import React, { useEffect, useState } from 'react';
import MPaper from './MPaper';
import { Box, Stack, Typography, colors, CircularProgress } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import the Firestore instance

const initialChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Modjo",
      data: Array(12).fill(0),
      stack: "stack 0",
      backgroundColor: colors.green[600],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    },
    {
      label: "Alemgena",
      data: Array(12).fill(0),
      stack: "stack 1",
      backgroundColor: colors.blue[300],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    },
    {
      label: "Sebeta",
      data: Array(12).fill(0),
      stack: "stack 2",
      backgroundColor: colors.red[300],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    },
    {
      label: "Cheffe-Donsa",
      data: Array(12).fill(0),
      stack: "stack 3",
      backgroundColor: colors.purple[300],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    },
    {
      label: "Lemmen",
      data: Array(12).fill(0),
      stack: "stack 4",
      backgroundColor: colors.orange[300],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    },
    {
      label: "Welliso",
      data: Array(12).fill(0),
      stack: "stack 5",
      backgroundColor: colors.yellow[300],
      barPercentage: 0.6,
      categoryPercentage: 0.7
    }
  ]
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
      stacked: true
    },
    y: { stacked: true }
  },
  plugins: {
    legend: { display: true },
    title: { display: false }
  },
  elements: {
    bar: {
      borderRadius: 10
    }
  }
};

const StatisticData = () => {
  const [chartData, setChartData] = useState(initialChartData);
  const [loading, setLoading] = useState(false);
  const [soldChange, setSoldChange] = useState(0);
  const [canceledChange, setCanceledChange] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const collections = {
        tickets_getout: "Modjo",
        tickets_getout_Alemgena: "Alemgena",
        tickets_getout_sebeta: "Sebeta",
        tickets_getout_CheffeDonsa: "Cheffe-Donsa",
        tickets_getout_leman: "Lemmen",
        tickets_getout_welliso: "Welliso",
      };

      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;

      let thisYearSold = 0;
      let lastYearSold = 0;
      let thisYearCanceled = 0;
      let lastYearCanceled = 0;

      const monthlyData = {
        Modjo: Array(12).fill(0),
        Alemgena: Array(12).fill(0),
        Sebeta: Array(12).fill(0),
        "Cheffe-Donsa": Array(12).fill(0),
        Lemmen: Array(12).fill(0),
        Welliso: Array(12).fill(0)
      };

      try {
        for (const [collectionName, route] of Object.entries(collections)) {
          for (let year = lastYear; year <= currentYear; year++) {
            for (let month = 0; month < 12; month++) {
              const startDate = new Date(year, month, 1);
              const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999); // Inclusive of the whole last day of the month

              const q = query(
                collection(db, collectionName),
                where('timestamp', ">=", startDate),
                where('timestamp', "<=", endDate)
              );

              const querySnapshot = await getDocs(q);

              querySnapshot.forEach((doc) => {
                const data = doc.data();

                Object.values(data).forEach((nestedData) => {
                  if (nestedData["Total Charge"]) {
                    const totalCharge = parseFloat(nestedData["Total Charge"]);
                    if (!isNaN(totalCharge)) {
                      if (year === currentYear) {
                        monthlyData[route][month] += totalCharge;
                        thisYearSold += totalCharge;
                      } else {
                        lastYearSold += totalCharge;
                      }
                    }
                  }

                  // Assuming you have a field "Status" that indicates whether a ticket is sold or canceled
                  if (nestedData["Status"] === "canceled") {
                    if (year === currentYear) {
                      thisYearCanceled++;
                    } else {
                      lastYearCanceled++;
                    }
                  }
                });
              });
            }
          }
        }

        const soldChange = ((thisYearSold - lastYearSold) / lastYearSold) * 100;
        const canceledChange = ((thisYearCanceled - lastYearCanceled) / lastYearCanceled) * 100;

        setSoldChange(soldChange);
        setCanceledChange(canceledChange);

        const newChartData = {
          ...initialChartData,
          datasets: initialChartData.datasets.map((dataset, index) => ({
            ...dataset,
            data: Object.values(monthlyData)[index]
          }))
        };

        setChartData(newChartData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <MPaper title="Statistic">
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            (+{soldChange.toFixed(2)}% sold | +{canceledChange.toFixed(2)}% canceled) than last year
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            {chartData.datasets.map((data, index) => (
              <Stack key={index} direction="row" alignItems="center">
                <Box sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "4px",
                  bgcolor: data.backgroundColor,
                  mr: 1
                }} />
                <Typography variant="subtitle2">
                  {data.label}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        {/* bar chart */}
        <Box>
          <Bar options={chartOptions} data={chartData} height="300px" />
        </Box>
        {/* bar chart */}
      </Stack>
    </MPaper>
  );
};

export default StatisticData;
