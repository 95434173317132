import React, { useEffect, useState } from 'react';
import {
  Box, Typography, CircularProgress, Card, CardContent, Grid, Stack, Pagination, TextField, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import { db } from "../firebase"; // Import the Firestore instance
import { collection, getDocs } from "firebase/firestore";
import MPaper from '../components/common/MPaper'; // Assuming you have an MPaper component
import Animate from "../components/common/Animate"; // Assuming you have an Animate component
import { colors } from '@mui/material'; // Adjust the path to your image
import { images } from "../assets";

const ITEMS_PER_PAGE = 20;

const ActiveCarsPage = () => {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [routeFilter, setRouteFilter] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const collections = [
        "tickets_getout",
        "tickets_getout_Alemgena",
        "tickets_getout_sebeta",
        "tickets_getout_CheffeDonsa",
        "tickets_getout_leman",
        "tickets_getout_welliso"
      ];

      const carDetailsSet = new Set();

      for (const collectionName of collections) {
        const querySnapshot = await getDocs(collection(db, collectionName));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          Object.values(data).forEach((item) => {
            if (item["Plate Number"]) {
              carDetailsSet.add(JSON.stringify({
                plateNumber: item["Plate Number"],
                seatCapacity: item["Seat Capacity"],
                route: item["Route"],
                level: item["Level"]
              }));
            }
          });
        });
      }

      const carArray = Array.from(carDetailsSet).map(car => JSON.parse(car));
      setCars(carArray);
      setFilteredCars(carArray);
      setLoading(false);
    };

    fetchData();
  }, []);

  const summaryData = [
    {
      value: filteredCars.length,
      title: 'Total Active Cars',
      image: images.summaryImages.cancel,
    },
  ];

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    filterCars(query, routeFilter);
  };

  const handleRouteFilter = (event) => {
    const route = event.target.value;
    setRouteFilter(route);
    filterCars(searchQuery, route);
  };

  const filterCars = (query, route) => {
    let filtered = cars;
    if (query) {
      filtered = filtered.filter(car => car.plateNumber.toLowerCase().includes(query));
    }
    if (route) {
      filtered = filtered.filter(car => car.route.toLowerCase().includes(route.toLowerCase()));
    }
    setFilteredCars(filtered);
    setPage(1); // Reset to the first page after filtering
  };

  const paginatedCars = filteredCars.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Active Cars
      </Typography>
      <Grid container spacing={3}>
        {summaryData.map((summary, index) => (
          <Grid key={index} item xs={12} lg={4}>
            <Animate type="fade" delay={(index + 1) / 3}>
              <MPaper>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack spacing={1}>
                    <Typography variant="h4" fontWeight="bold">
                      {summary.value}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={colors.grey[600]}
                    >
                      {summary.title}
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      height: "100px",
                      width: "100px",
                      "& img": { width: "100%" },
                    }}
                  >
                    <img src={summary.image} alt="summary" />
                  </Box>
                </Stack>
              </MPaper>
            </Animate>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <TextField
          label="Search by Plate Number"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          sx={{ marginRight: 2 }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Main Route</InputLabel>
          <Select
            value={routeFilter}
            onChange={handleRouteFilter}
            label="Main Route"
          >
            <MenuItem value="">All Routes</MenuItem>
            <MenuItem value="Mojo">Mojo</MenuItem>
            <MenuItem value="Alemgena">Alemgena</MenuItem>
            <MenuItem value="Sebeta">Sebeta</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            {paginatedCars.length > 0 ? (
              paginatedCars.map((car, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        Plate Number: {car.plateNumber}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Seat Capacity: {car.seatCapacity}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Route: {car.route}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Level: {car.level}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1">No active cars found.</Typography>
            )}
          </Grid>
          <Box mt={3} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(filteredCars.length / ITEMS_PER_PAGE)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ActiveCarsPage;
