// src/contexts/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const createUserRole = async (uid, role = 'guest') => {
  const userRef = doc(db, 'roles', uid);
  try {
    await setDoc(userRef, { role });
    console.log('User role document created for UID:', uid);
    return role;
  } catch (error) {
    console.error('Error creating user role document:', error);
    return 'guest';
  }
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        console.log('User authenticated:', userAuth.uid);
        const userRef = doc(db, 'roles', userAuth.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            console.log('User document found:', userDoc.data());
            setUser({ uid: userAuth.uid, role: userDoc.data().role });
          } else {
            console.log('No user document found. Creating one...');
            const role = await createUserRole(userAuth.uid);
            setUser({ uid: userAuth.uid, role });
          }
        } catch (error) {
          console.error('Error fetching/creating user document:', error);
          setUser({ uid: userAuth.uid, role: 'guest' });
        }
      } else {
        console.log('No user authenticated');
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};