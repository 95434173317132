export const images = {
  loginBg: require("./images/3d-render-tax-payment-financial-business-concept.png"),
  summaryImages: {
    totalBook: require("./images/perspective_matte-385-128x128.png"),
    sold: require("./images/users.png"),
    cancel: require("./images/c.png")
  },
  userProfile: require("./images/7309681.jpg"),
  bookingImage: require("./images/cherry-blossoms-fuji-mountain-spring-sunrise-shizuoka-japan.jpg"),
  logo: require("./images/logo.png")
};