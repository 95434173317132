// routes.js
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import { useUser } from "./UserContext";
import AppLayout from "../components/layout/AppLayout";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../components/layout/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import InboxPage from "../pages/InboxPage";
import AddUserAndVehicle from "../pages/NotificationPage";
import ActiveSalesPage from "../pages/ActiveSalesPage";
import ActiveCarsPage from "../pages/ActiveCarsPage";
import TodayTicketsPage from "../pages/TodayTicketsPage";
import TicketsPage from "../pages/tuckets";
import StationsPage from "../pages/StationsPage";
import Salesactivity from "../pages/Salesactivity";
import Users from "../pages/InboxPage";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const user = useUser();
  const location = useLocation();
  
  if (user === null) {
    // User data is still being fetched, you might want to show a loading indicator here
    return <div>Loading...</div>;
  }

  if (!user.uid) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  if (allowedRoles.includes(user.role)) {
    return element;
  } else {
    return <Navigate to="/dashboard/tickets" replace state={{ from: location }} />;
  }
};


export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <LoginPage />
      },
      {
        path: "dashboard",
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={<DashboardPage />} allowedRoles={['admin']} />
          },
          {
            path: "overview",
            element: <ProtectedRoute element={<DashboardPage />} allowedRoles={['admin']} />
          },
          {
            path: "Users",
            element: <ProtectedRoute element={<Users />} allowedRoles={['admin']} />
          },
          {
            path: "inbox",
            element: <ProtectedRoute element={<Users />} allowedRoles={['admin']} />
          },
          {
            path: "AddUserAndVehicle",
            element: <ProtectedRoute element={<AddUserAndVehicle />} allowedRoles={['admin']} />
          },
          {
            path: "mortage",
            element: <ProtectedRoute element={<ActiveSalesPage />} allowedRoles={['admin']} />
          },
          {
            path: "carloan",
            element: <ActiveCarsPage />
          },
         
          {
            path: "stations",
            element: <ProtectedRoute element={<StationsPage />} allowedRoles={['admin']} />
          },
          {
            path: "SalesActivity",
            element: <ProtectedRoute element={<Salesactivity />} allowedRoles={['admin']} />
          },
          {
            path: "ticketsb",
            element: <ProtectedRoute element={<TicketsPage />} allowedRoles={['admin']} />
          },
          {
            path: "tickets",
            element: <TodayTicketsPage /> // Both admin and guest can access this
          },
        ]
      }
    ]
  }
]);