import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import { db } from "../firebase"; // Import the Firestore instance
import { collection, getDocs } from "firebase/firestore";
import dayjs from 'dayjs'; // for date manipulation
import duration from 'dayjs/plugin/duration'; 
import relativeTime from 'dayjs/plugin/relativeTime'; 
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const ActiveSalesPage = () => {
  const [users, setUsers] = useState([]);
  const [activeSales, setActiveSales] = useState([]);
  const [loading, setLoading] = useState(true);

  const [adminCount, setAdminCount] = useState(0);
  const [supervisorCount, setSupervisorCount] = useState(0);
  const [salesCount, setSalesCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const codesSnapshot = await getDocs(collection(db, "codes"));
      const codesData = codesSnapshot.docs.map(doc => doc.data());

      const today = dayjs().startOf('day');
      const lastWeek = dayjs().subtract(7, 'day').startOf('day');

      const loggedInToday = usersData.filter(user => 
        user.loginDateTime && user.loginDateTime.some(date => dayjs(date).isAfter(today))
      ).map(user => {
        const loginTimes = user.loginDateTime.filter(date => dayjs(date).isAfter(today)).map(date => dayjs(date));
        const mostRecentLoginTime = loginTimes.length > 0 ? loginTimes.reduce((a, b) => a.isAfter(b) ? a : b) : null;
        const totalActiveTime = loginTimes.reduce((total, date) => {
          return total.add(dayjs(date).diff(today));
        }, dayjs.duration(0));

        const codeInfo = codesData.find(code => code.code === user.id);
        
        return {
          ...user,
          full_name: codeInfo ? codeInfo.full_name : 'Unknown',
          userType: codeInfo ? codeInfo.userType : 'Unknown',
          mostRecentLoginTime,
          totalActiveTime: totalActiveTime.asHours() > 0 ? `${Math.floor(totalActiveTime.asHours())} hours ${Math.floor(totalActiveTime.asMinutes() % 60)} minutes` : 'Less than a minute'
        };
      });

      const activeWithinWeek = usersData.filter(user => 
        user.loginDateTime && user.loginDateTime.some(date => dayjs(date).isAfter(lastWeek))
      ).map(user => {
        const codeInfo = codesData.find(code => code.code === user.id);
        return {
          ...user,
          full_name: codeInfo ? codeInfo.full_name : 'Unknown',
          userType: codeInfo ? codeInfo.userType : 'Unknown'
        };
      });

      setUsers(loggedInToday);
      setActiveSales(activeWithinWeek);
      setLoading(false);

      // Calculate the total number of users for each user type
      const adminCount = usersData.filter(user => user.userType === 'admin').length;
      const supervisorCount = usersData.filter(user => user.userType === 'supervisor').length;
      const salesCount = usersData.filter(user => user.userType === 'sales').length;

      setAdminCount(adminCount);
      setSupervisorCount(supervisorCount);
      setSalesCount(salesCount);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateUserCounts = () => {
      const adminCount = users.filter(user => user.userType === 'admin').length;
      const supervisorCount = users.filter(user => user.userType === 'supervisor').length;
      const salesCount = users.filter(user => user.userType === 'sales').length;
      setAdminCount(adminCount);
      setSupervisorCount(supervisorCount);
      setSalesCount(salesCount);
    };

    calculateUserCounts();
  }, [users]);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Active Sales
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box mb={3}>
            <Typography variant="h6">Total Users Count</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center">{adminCount}</Typography>
                    <Typography variant="body2" align="center">Admin</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center">{supervisorCount}</Typography>
                    <Typography variant="body2" align="center">Supervisor</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center">{salesCount}</Typography>
                    <Typography variant="body2" align="center">Sales</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h6" gutterBottom>
            Users Logged In Today
          </Typography>
          <Grid container spacing={2} mb={3}>
            {users.length > 0 ? (
              users.map(user => (
                <Grid item xs={12} sm={6} md={4} key={user.id}>
                  <Card sx={{ backgroundColor: user.userType === 'admin' ? 'lightblue' : user.userType === 'supervisor' ? 'lightgreen' : 'white' }}>
                    <CardContent>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" color={user.userType === 'admin' ? 'primary' : user.userType === 'supervisor' ? 'success.dark' : 'textPrimary'}>
                          {user.userType === 'admin' ? <AdminPanelSettingsIcon color="primary" /> : user.userType === 'supervisor' ? <SupervisorAccountIcon color="action" /> : <MonetizationOnIcon color="secondary" />}
                          {user.full_name} ({user.id})
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        Recent Login: {user.mostRecentLoginTime ? user.mostRecentLoginTime.format('YYYY-MM-DD hh:mm:ss A') : 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Total Active Time Today: {user.totalActiveTime}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1">No users logged in today.</Typography>
            )}
          </Grid>

          <Typography variant="h6" gutterBottom>
            Active Sales Within Last Week
          </Typography>
          <Grid container spacing={2}>
            {activeSales.length > 0 ? (
              activeSales.map(sale => (
                <Grid item xs={12} sm={6} md={4} key={sale.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">
                        {sale.userType === 'admin' ? <AdminPanelSettingsIcon color="primary" /> : sale.userType === 'supervisor' ? <SupervisorAccountIcon color="action" /> : <MonetizationOnIcon color="secondary" />}
                        {sale.full_name} ({sale.id})
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {sale.userType}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1">No active sales found.</Typography>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ActiveSalesPage;