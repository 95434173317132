import React, { useState, useEffect } from "react";
import { doc, setDoc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./AddUserAndVehicle.css";

const AddUserAndVehicle = () => {
  const [user, setUser] = useState({
    code: "",
    email: "",
    fullName: "",
    phoneNumber: "",
    registrationTime: new Date(),
    userType: "",
    workingRoute: ""
  });

  const [vehicle, setVehicle] = useState({
    destination: "Taree",
    driver: "Default Driver",
    level: "3",
    netPrice: "24",
    plateNumber: "",
    price: "23",
    route: "Leman",
    seatCapacity: "13",
    serviceCharge: "1",
    startStation: "Leman",
    address: "Default Address",
    association: "Default Association",
    carModel: "Default Car Model",
    color: "Default Color",
    createdBy: "Default Created By",
    createdOn: new Date(),
    distanceKm: "Default Distance KM",
    kebele: "Default Kebele",
    licenseNumber: "Default License Number",
    ownerAddress: "Default Owner Address",
    ownerName: "Default Owner Name",
    ownerPhoneNumber: "Default Owner Phone Number",
    phoneNumber: "Default Phone Number",
    phoneNumber2: "Default Phone Number 2",
    region: "Default Region",
    sideNumber: "Default Side Number",
    status: "Default Status",
    woreda: "Default Woreda",
    zoneSubcity: "Default Zone/Subcity",
    timestamp: new Date()
  });

  const [cars, setCars] = useState([]);
  const [editVehicle, setEditVehicle] = useState(null);

  const handleUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleVehicleChange = (e) => {
    setVehicle({ ...vehicle, [e.target.name]: e.target.value });
  };

  const addUserToFirestore = async () => {
    try {
      await setDoc(doc(db, "codes", user.code), {
        code: user.code,
        email: user.email,
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        registrationTime: user.registrationTime,
        userType: user.userType,
        ...(user.userType !== "admin" && { workingRoute: user.workingRoute })
      });
      alert("User added successfully!");
    } catch (error) {
      console.error("Error adding user: ", error);
    }
  };

  const addVehicleToFirestore = async () => {
    try {
      await setDoc(doc(db, "cars", vehicle.plateNumber), vehicle, { merge: true });
      alert("Vehicle added successfully!");
      fetchCars();
    } catch (error) {
      console.error("Error adding vehicle: ", error);
    }
  };

  const fetchCars = async () => {
    const querySnapshot = await getDocs(collection(db, "cars"));
    const carsData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      carsData.push({
        id: doc.id,
        ...data,
        createdOn: data.createdOn ? data.createdOn.toDate() : null // Ensure createdOn is a Date object or null
      });
    });
    setCars(carsData);
  };
  
  
  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : 'N/A'; // Format date or return 'N/A' if undefined
  };
  
  
  // In the JSX part where you map over cars:
  {cars.map((car) => (
    <div key={car.id} className="car-card">
      <h3>{car.plateNumber}</h3>
      <p><strong>Destination:</strong> {car.destination}</p>
      <p><strong>Driver:</strong> {car.driver}</p>
      <p><strong>Level:</strong> {car.level}</p>
      <p><strong>Net Price:</strong> {car.netPrice}</p>
      <p><strong>Price:</strong> {car.price}</p>
      <p><strong>Route:</strong> {car.route}</p>
      <p><strong>Start Station:</strong> {car.startStation}</p>
      <p><strong>Seat Capacity:</strong> {car.seatCapacity}</p>
      <p><strong>Service Charge:</strong> {car.serviceCharge}</p>
      <p><strong>Address:</strong> {car.address}</p>
      <p><strong>Association:</strong> {car.association}</p>
      <p><strong>Car Model:</strong> {car.carModel}</p>
      <p><strong>Color:</strong> {car.color}</p>
      <p><strong>Created By:</strong> {car.createdBy}</p>
      <p><strong>Created On:</strong> {formatDate(car.createdOn)}</p>
      <p><strong>Distance KM:</strong> {car.distanceKm}</p>
      <p><strong>Kebele:</strong> {car.kebele}</p>
      <p><strong>License Number:</strong> {car.licenseNumber}</p>
      <p><strong>Owner Address:</strong> {car.ownerAddress}</p>
      <p><strong>Owner Name:</strong> {car.ownerName}</p>
      <p><strong>Owner Phone Number:</strong> {car.ownerPhoneNumber}</p>
      <p><strong>Phone Number:</strong> {car.phoneNumber}</p>
      <p><strong>Phone Number 2:</strong> {car.phoneNumber2}</p>
      <p><strong>Region:</strong> {car.region}</p>
      <p><strong>Side Number:</strong> {car.sideNumber}</p>
      <p><strong>Status:</strong> {car.status}</p>
      <p><strong>Woreda:</strong> {car.woreda}</p>
      <p><strong>Zone/Subcity:</strong> {car.zoneSubcity}</p>
      <button onClick={() => handleEditVehicle(car)}>Edit</button>
      <button onClick={() => handleDeleteVehicle(car.id)}>Delete</button>
    </div>
  ))}
  

  const handleEditVehicle = (car) => {
    setEditVehicle(car);
    setVehicle(car);
  };

  const handleUpdateVehicle = async () => {
    try {
      await setDoc(doc(db, "cars", editVehicle.id), vehicle, { merge: true });
      alert("Vehicle updated successfully!");
      setEditVehicle(null);
      fetchCars();
    } catch (error) {
      console.error("Error updating vehicle: ", error);
    }
  };

  const handleDeleteVehicle = async (vehicleId) => {
    try {
      await deleteDoc(doc(db, "cars", vehicleId));
      alert("Vehicle deleted successfully!");
      fetchCars();
    } catch (error) {
      console.error("Error deleting vehicle: ", error);
    }
  };

  useEffect(() => {
    fetchCars();
  }, []);

  return (
    <div className="form-container">
      <h2>Add User</h2>
      <div className="form-group">
        <input type="text" name="code" placeholder="Code" onChange={handleUserChange} />
        <input type="text" name="email" placeholder="Email" onChange={handleUserChange} />
        <input type="text" name="fullName" placeholder="Full Name" onChange={handleUserChange} />
        <input type="text" name="phoneNumber" placeholder="Phone Number" onChange={handleUserChange} />
        <input type="text" name="userType" placeholder="User Type" onChange={handleUserChange} />
        {user.userType !== "admin" && (
          <input type="text" name="workingRoute" placeholder="Working Route" onChange={handleUserChange} />
        )}
      </div>
      <button onClick={addUserToFirestore}>Add User</button>

      <h2>{editVehicle ? "Edit Vehicle" : "Add Vehicle"}</h2>
      <div className="form-group">
        <input type="text" name="plateNumber" placeholder="Plate Number" onChange={handleVehicleChange} value={vehicle.plateNumber} />
        <input type="text" name="destination" placeholder="Destination" onChange={handleVehicleChange} value={vehicle.destination} />
        <input type="text" name="netPrice" placeholder="Net Price" onChange={handleVehicleChange} value={vehicle.netPrice} />
        <input type="text" name="price" placeholder="Price" onChange={handleVehicleChange} value={vehicle.price} />
        <input type="text" name="route" placeholder="Route" onChange={handleVehicleChange} value={vehicle.route} />
        <input type="text" name="startStation" placeholder="Start Station" onChange={handleVehicleChange} value={vehicle.startStation} />
        <input type="text" name="driver" placeholder="Driver" onChange={handleVehicleChange} value={vehicle.driver} />
        <input type="text" name="level" placeholder="Level" onChange={handleVehicleChange} value={vehicle.level} />
        <input type="text" name="seatCapacity" placeholder="Seat Capacity" onChange={handleVehicleChange} value={vehicle.seatCapacity} />
        <input type="text" name="serviceCharge" placeholder="Service Charge" onChange={handleVehicleChange} value={vehicle.serviceCharge} />
        <input type="text" name="address" placeholder="Address" onChange={handleVehicleChange} value={vehicle.address} />
        <input type="text" name="association" placeholder="Association" onChange={handleVehicleChange} value={vehicle.association} />
        <input type="text" name="carModel" placeholder="Car Model" onChange={handleVehicleChange} value={vehicle.carModel} />
        <input type="text" name="color" placeholder="Color" onChange={handleVehicleChange} value={vehicle.color} />
        <input type="text" name="createdBy" placeholder="Created By" onChange={handleVehicleChange} value={vehicle.createdBy} />
        <input type="date" name="createdOn" placeholder="Created On" onChange={handleVehicleChange} value={vehicle.createdOn} />
        <input type="text" name="distanceKm" placeholder="Distance KM" onChange={handleVehicleChange} value={vehicle.distanceKm} />
        <input type="text" name="kebele" placeholder="Kebele" onChange={handleVehicleChange} value={vehicle.kebele} />
        <input type="text" name="licenseNumber" placeholder="License Number" onChange={handleVehicleChange} value={vehicle.licenseNumber} />
        <input type="text" name="ownerAddress" placeholder="Owner Address" onChange={handleVehicleChange} value={vehicle.ownerAddress} />
        <input type="text" name="ownerName" placeholder="Owner Name" onChange={handleVehicleChange} value={vehicle.ownerName} />
        <input type="text" name="ownerPhoneNumber" placeholder="Owner Phone Number" onChange={handleVehicleChange} value={vehicle.ownerPhoneNumber} />
        <input type="text" name="phoneNumber" placeholder="Phone Number" onChange={handleVehicleChange} value={vehicle.phoneNumber} />
        <input type="text" name="phoneNumber2" placeholder="Phone Number 2" onChange={handleVehicleChange} value={vehicle.phoneNumber2} />
        <input type="text" name="region" placeholder="Region" onChange={handleVehicleChange} value={vehicle.region} />
        <input type="text" name="sideNumber" placeholder="Side Number" onChange={handleVehicleChange} value={vehicle.sideNumber} />
        <input type="text" name="status" placeholder="Status" onChange={handleVehicleChange} value={vehicle.status} />
        <input type="text" name="woreda" placeholder="Woreda" onChange={handleVehicleChange} value={vehicle.woreda} />
        <input type="text" name="zoneSubcity" placeholder="Zone/Subcity" onChange={handleVehicleChange} value={vehicle.zoneSubcity} />
      </div>
      {editVehicle ? (
        <button onClick={handleUpdateVehicle}>Update Vehicle</button>
      ) : (
        <button onClick={addVehicleToFirestore}>Add Vehicle</button>
      )}

      <h2>Saved Cars</h2>
      <div className="cars-list">
  {cars.map((car) => (
    <div key={car.id} className="car-card">
      <h3>{car.plateNumber}</h3>
      <p><strong>Destination:</strong> {car.destination}</p>
      <p><strong>Driver:</strong> {car.driver}</p>
      <p><strong>Level:</strong> {car.level}</p>
      <p><strong>Net Price:</strong> {car.netPrice}</p>
      <p><strong>Price:</strong> {car.price}</p>
      <p><strong>Route:</strong> {car.route}</p>
      <p><strong>Start Station:</strong> {car.startStation}</p>
      <p><strong>Seat Capacity:</strong> {car.seatCapacity}</p>
      <p><strong>Service Charge:</strong> {car.serviceCharge}</p>
      <p><strong>Address:</strong> {car.address}</p>
      <p><strong>Association:</strong> {car.association}</p>
      <p><strong>Car Model:</strong> {car.carModel}</p>
      <p><strong>Color:</strong> {car.color}</p>
      <p><strong>Created By:</strong> {car.createdBy}</p>
      <p><strong>Created On:</strong> {formatDate(car.createdOn)}</p>
      <p><strong>Distance KM:</strong> {car.distanceKm}</p>
      <p><strong>Kebele:</strong> {car.kebele}</p>
      <p><strong>License Number:</strong> {car.licenseNumber}</p>
      <p><strong>Owner Address:</strong> {car.ownerAddress}</p>
      <p><strong>Owner Name:</strong> {car.ownerName}</p>
      <p><strong>Owner Phone Number:</strong> {car.ownerPhoneNumber}</p>
      <p><strong>Phone Number:</strong> {car.phoneNumber}</p>
      <p><strong>Phone Number 2:</strong> {car.phoneNumber2}</p>
      <p><strong>Region:</strong> {car.region}</p>
      <p><strong>Side Number:</strong> {car.sideNumber}</p>
      <p><strong>Status:</strong> {car.status}</p>
      <p><strong>Woreda:</strong> {car.woreda}</p>
      <p><strong>Zone/Subcity:</strong> {car.zoneSubcity}</p>
      <button onClick={() => handleEditVehicle(car)}>Edit</button>
      <button onClick={() => handleDeleteVehicle(car.id)}>Delete</button>
    </div>

        ))}
      </div>
    </div>
  );
};

export default AddUserAndVehicle;
