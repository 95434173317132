import React, { useEffect, useState } from 'react';
import MPaper from '../components/common/MPaper';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Import the Firestore instance
import { Box, Typography, CircularProgress } from "@mui/material";
import { Grid, Card, CardContent } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import dayjs from "dayjs"; // for date manipulation
import { Bar } from 'react-chartjs-2';
import { colors } from '@mui/material';

// Define a list of colors for the bars
const COLORS = [colors.green[600], colors.blue[300], colors.red[300]];

// Recursive function to get the total charge and ticket count from nested objects
const processNestedData = (obj, accumulator) => {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object') {
          processNestedData(value, accumulator);
        } else if (key === 'Total Charge') {
          const charge = parseFloat(value);
          if (!isNaN(charge)) {
            accumulator.totalCharge += charge;
          }
        } else if (key === 'Ticket Count') {
          const ticketCount = parseInt(value, 10);
          if (!isNaN(ticketCount)) {
            accumulator.ticketCount += ticketCount;
          }
        }
      }
    }
  }
};

const Salesactivity = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userCodesData, setUserCodesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const collections = {
        tickets_getout: "Modjo",
        tickets_getout_Alemgena: "Alemgena",
        tickets_getout_sebeta: "Sebeta",
        tickets_getout_CheffeDonsa: "Cheffe-Donsa",
        tickets_getout_leman: "Lemmen",
        tickets_getout_welliso: "Welliso",
        tickets_getout_Tajji: "Tajji"
      };

      const totalServiceCharges = {};
      const today = dayjs().format("YYYY-MM-DD");

      // Fetch data for the current day
      for (const [collectionName, route] of Object.entries(collections)) {
        const docRef = doc(db, collectionName, today);
        const docSnap = await getDoc(docRef);

        // Add console logs here
        console.log(`Fetching data for ${route}`);
        console.log(docSnap.exists() ? `Document exists for ${route}` : `No document for ${route}`);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log(`Data for ${route}:`, data);

          Object.values(data).forEach((nestedData) => {
            if (
              nestedData["Total Charge"] &&
              nestedData["userCode"] &&
              nestedData["Sales Name"]
            ) {
              const userCode = nestedData["userCode"];
              const userCodeName = nestedData["Sales Name"];
              const accumulator = { totalCharge: 0, ticketCount: 0 };
              processNestedData(nestedData, accumulator);
              if (accumulator.totalCharge > 0 || accumulator.ticketCount > 0) {
                if (!totalServiceCharges[route]) {
                  totalServiceCharges[route] = {};
                }
                if (!totalServiceCharges[route][userCode]) {
                  totalServiceCharges[route][userCode] = {
                    userCodeName: userCodeName,
                    totalCharge: 0,
                    ticketCount: 0,
                  };
                }
                totalServiceCharges[route][userCode].totalCharge += accumulator.totalCharge;
                totalServiceCharges[route][userCode].ticketCount += accumulator.ticketCount;
              }
            }
          });
        }
      }

      const updatedSalesData = Object.entries(totalServiceCharges).map(
        ([route, userCodes]) => {
          const totalSales = Object.values(userCodes).reduce(
            (acc, curr) => acc + curr.totalCharge,
            0
          );
          const totalTickets = Object.values(userCodes).reduce(
            (acc, curr) => acc + curr.ticketCount,
            0
          );
          const userCodesWithPercentage = Object.entries(userCodes).map(
            ([userCode, userData]) => ({
              userCode: userCode,
              userCodeName: userData.userCodeName,
              value: `${userData.totalCharge.toFixed(2)} birr`,
              tickets: userData.ticketCount,
              percent: ((userData.totalCharge / totalSales) * 100).toFixed(2),
            })
          );
          return {
            route,
            totalSales,
            totalTickets,
            userCodesWithPercentage,
          };
        }
      );

      setSalesData(updatedSalesData);

      // Fetch data for the entire current month
      const monthlyServiceCharges = {};
      const startOfMonth = dayjs().startOf('month');
      const endOfMonth = dayjs().endOf('month');
      const dates = [];

      for (let date = startOfMonth; date.isBefore(endOfMonth); date = date.add(1, 'day')) {
        dates.push(date.format("YYYY-MM-DD"));
      }

      for (const [collectionName, route] of Object.entries(collections)) {
        for (const date of dates) {
          const docRef = doc(db, collectionName, date);
          const docSnap = await getDoc(docRef);

          // Add console logs here for monthly data
          console.log(`Fetching monthly data for ${route} on ${date}`);
          console.log(docSnap.exists() ? `Document exists for ${route} on ${date}` : `No document for ${route} on ${date}`);

          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log(`Monthly data for ${route} on ${date}:`, data);

            Object.values(data).forEach((nestedData) => {
              if (
                nestedData["Total Charge"] &&
                nestedData["userCode"] &&
                nestedData["Sales Name"]
              ) {
                const userCode = nestedData["userCode"];
                const userCodeName = nestedData["Sales Name"];
                const accumulator = { totalCharge: 0, ticketCount: 0 };
                processNestedData(nestedData, accumulator);
                if (accumulator.totalCharge > 0 || accumulator.ticketCount > 0) {
                  if (!monthlyServiceCharges[userCode]) {
                    monthlyServiceCharges[userCode] = {
                      userCodeName: userCodeName,
                      totalCharge: 0,
                      ticketCount: 0,
                    };
                  }
                  monthlyServiceCharges[userCode].totalCharge += accumulator.totalCharge;
                  monthlyServiceCharges[userCode].ticketCount += accumulator.ticketCount;
                }
              }
            });
          }
        }
      }

      const updatedUserCodesData = Object.entries(monthlyServiceCharges).map(([userCode, userData]) => ({
        userCode: userCode,
        userCodeName: userData.userCodeName,
        totalCharge: userData.totalCharge.toFixed(2),
        ticketCount: userData.ticketCount,
      }));

      setUserCodesData(updatedUserCodesData);
      setLoading(false);
    };

    fetchData();
  }, []);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        stacked: true
      },
      y: { stacked: true }
    },
    plugins: {
      legend: { display: true },
      title: { display: false }
    },
    elements: {
      bar: {
        borderRadius: 10
      }
    }
  };

  return (
    <MPaper title="Sales Activity" fullHeight>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {salesData.map((data, index) => (
            <Box key={index} mb={3}>
              <Typography variant="h6">{`Route: ${data.route}`}</Typography>
              <Typography variant="subtitle1">{`Total Tickets: ${data.totalTickets}`}</Typography>
              <Grid container spacing={2}>
                {data.userCodesWithPercentage.map((userCodeData, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h7">
                          {userCodeData.userCode.startsWith("admin") ? (
                            <AdminPanelSettingsIcon color="primary" />
                          ) : userCodeData.userCode.startsWith("supervisor") ? (
                            <SupervisorAccountIcon color="action" />
                          ) : (
                            <MonetizationOnIcon color="secondary" />
                          )}
                          {`${userCodeData.userCode} - ${userCodeData.userCodeName}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {`Total Charge: ${userCodeData.value}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {`Total Tickets: ${userCodeData.tickets}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {`Contribution: ${userCodeData.percent}%`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box mt={3}>
                <Typography variant="h6">{`Bar Chart for Route: ${data.route}`}</Typography>
                <Box sx={{ height: 400 }}>
                  <Bar 
                    options={chartOptions} 
                    data={{
                      labels: data.userCodesWithPercentage.map(userCodeData => userCodeData.userCodeName),
                      datasets: [{
                        label: data.route,
                        data: data.userCodesWithPercentage.map(userCodeData => parseFloat(userCodeData.value)),
                        backgroundColor: COLORS[index % COLORS.length],
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                      }]
                    }} 
                  />
                </Box>
              </Box>
            </Box>
          ))}
          <Box mt={3}>
            <Typography variant="h6">{`Bar Chart for User Codes Total Charge for Current Month`}</Typography>
            <Box sx={{ height: 400 }}>
              <Bar 
                options={chartOptions} 
                data={{
                  labels: userCodesData.map(data => data.userCodeName),
                  datasets: [{
                    label: 'Total Charge',
                    data: userCodesData.map(data => parseFloat(data.totalCharge)),
                    backgroundColor: COLORS,
                    barPercentage: 0.6,
                    categoryPercentage: 0.7
                  }]
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </MPaper>
  );
};

export default Salesactivity;
