import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import AdminIcon from'@mui/icons-material/AdminPanelSettings';
import AddIcon from'@mui/icons-material/InsertChart';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import SportsMotorsportsOutlinedIcon from '@mui/icons-material/SportsMotorsportsOutlined';
import SportsMotorsportsOutlinedIconb from '@mui/icons-material/Receipt';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, colors } from '@mui/material';
import { images } from "../../assets";
import Animate from "./Animate";
import { BusAlertOutlined } from '@mui/icons-material';

const menus = [
  {
    title: "Admin Controller",
    icon: <AdminIcon />,
    state: "Users",
    path: "/dashboard/inbox"
  },
  {
    title: "Overview",
    icon: <DashboardCustomizeOutlinedIcon />,
    state: "overview",
    path: "/dashboard/overview"
  },
  {
    title: "Add Cars & Users",
    icon: <AddIcon />,
    state: "AddUserAndVehicle",
    path: "/dashboard/AddUserAndVehicle"
  }
];

const serviceMenus = [
  {
    title: "Active Sales",
    icon: <OtherHousesOutlinedIcon />,
    state: "mortage",
    path: "/dashboard/mortage"
  },
  {
    title: "Sales Activity",
    icon: <OtherHousesOutlinedIcon />,
    state: "SalesActivity",
    path: "/dashboard/SalesActivity"
  },
  {
    title: "Active Cars",
    icon: <DirectionsCarFilledOutlinedIcon />,
    state: "carloan",
    path: "/dashboard/carloan"
  },
  {
    title: "Tickets",
    icon: <SportsMotorsportsOutlinedIconb />,
    state: "ticketsb",
    path: "/dashboard/ticketsb"
  },
  {
    title: "Today Tickets",
    icon: <SportsMotorsportsOutlinedIcon />,
    state: "tickets",
    path: "/dashboard/tickets"
  },
  {
    title: "Stations",
    icon: <BusAlertOutlined />,
    state: "stations",
    path: "/dashboard/stations"
  }
];

const investmentMenus = [

];

const Sidebar = ({ sidebarWidth }) => {
  const location = useLocation();
  let activeState = location.pathname.split('/').pop();

  // Check if the activeState is a valid menu state
  const allMenuStates = [...menus, ...serviceMenus].map(menu => menu.state);
  if (!allMenuStates.includes(activeState)) {
    activeState = "overview";
  }

  const MenuItem = ({ item }) => (
    <ListItem disableGutters disablePadding sx={{ py: 0.5 }}>
      <ListItemButton 
        component={Link} 
        to={item.path} 
        sx={{
          borderRadius: "10px",
          bgcolor: item.state === activeState ? colors.green[600] : "",
          color: item.state === activeState ? colors.common.white : "",
          "&:hover": {
            bgcolor: item.state === activeState ? colors.green[600] : "",
            color: item.state === activeState ? colors.common.white : "",
          }
        }}
      >
        <ListItemIcon sx={{
          minWidth: "40px",
          color: item.state === activeState ? colors.common.white : ""
        }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={
          <Typography fontWeight={600}>
            {item.title}
          </Typography>
        } />
      </ListItemButton>
    </ListItem>
  );

  const drawer = (
    <Box
      padding={3}
      paddingBottom={0}
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        "::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      {/* logo */}
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Animate type="fade" delay={1}>
          <img src={images.logo} alt="logo" height={60} />
        </Animate>
      </Box>
      {/* logo */}

      <Animate sx={{ flexGrow: 1 }}>
        <Paper
          elevation={0}
          square
          sx={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            p: 2,
            height: "100%",
            boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
          }}
        >
          {/* menu group 1 */}
          <List>
            {menus.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
              />
            ))}
          </List>
          {/* menu group 1 */}

          {/* menu group 2 */}
          <List>
            <ListItem>
              <Typography fontWeight={600} mt={1} color={colors.grey[600]}>
                Services
              </Typography>
            </ListItem>
            {serviceMenus.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
              />
            ))}
          </List>
          {/* menu group 2 */}
        </Paper>
      </Animate>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: sidebarWidth },
        flexShrink: { md: 0 }
      }}
    >
      {/* large screen */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sidebarWidth,
            borderWidth: 0,
            bgcolor: "transparent",
            "::-webkit-scrollbar": {
              display: "none"
            }
          }
        }}
        open
      >
        {drawer}
      </Drawer>
      {/* large screen */}
    </Box>
  );
};

export default Sidebar;
